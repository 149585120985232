import Layout from 'gatsby-theme-carbon/src/components/Layout';
import Main from 'gatsby-theme-carbon/src/components/Main';
import PageHeader from 'gatsby-theme-carbon/src/components/PageHeader';
import Utils from 'gatsby-theme-carbon/src/components/Utils';
import React from 'react';
import { graphql, Link } from 'gatsby';
// import ProductsIndexPage from '../../components/ProductsIndex';
import {
    Breadcrumb,
    BreadcrumbItem,
    Row,
    Column
} from "@carbon/react";
import BrandsIndexPage from '../../components/BrandsIndex';


export const query = graphql`
  query($id: String!) {
    industratorCategory(id: { eq: $id }) {
        id
        title
        title_path
        title_path_full
        tree
    }
  }
`;

export async function config() {
    // Optionally use GraphQL here
    return ({ params }) => {
        return {
            defer: false,
        }
    }
}

function Category(props) {
    const { industratorCategory } = props.data

    return (
        <Layout
            theme={"white"}
            homepage={false}
            titleType='prepend'
            pageTitle={`Купить продукцию ${industratorCategory.title_path_full}`}
            pageDescription={`Купить продукцию ${industratorCategory.title_path_full}`}
            pageKeywords={`Купить продукцию ${industratorCategory.title_path_full}`}
        >
            <PageHeader
                title={`${industratorCategory.title_path_full}`}
                label="label"
                tabs={[]}
                theme={'dark'}
            />
            <Main padded>
                <Row>
                    <Column>
                        <Breadcrumb noTrailingSlash>
                            <BreadcrumbItem href="/">
                                <Link to={'/'}>Главная
                                </Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem isCurrentPage>
                                {/* <a> */}
                                {industratorCategory.title_path_full}
                                {/* </a> */}
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </Column>
                </Row>

                <Row>
                    <Column>
                        <BrandsIndexPage hideBrand filter={{
                            "categories": [industratorCategory.id]
                        }} />
                    </Column>
                </Row>

                {/* <ProductsIndexPage hideBrand filter={{ brands: [`${industratorCategory.title_path_full}`] }} /> */}
            </Main>
            <Utils />
        </Layout >
    );
}

export default Category;
